.Registrations{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
}

.head{
    font-size: xx-large;
    padding: 1rem 1rem 2rem 1rem;
    color: black;
    background-color: rgb(17, 75, 137);
    width: 100%;
    text-align: center;
    font-weight: 450;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
    color: white;
} 

.til{
    min-width: 400px;
}

.list{
    width: 100%;
    border: 1px solid rgb(167, 164, 164);
    padding: 0 1rem 0 1rem;
    margin-bottom: 3rem;
    overflow-x: scroll;
}