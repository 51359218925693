.Registrations{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
}

.head{
    font-size: xx-large;
    padding: 2rem 0rem 2rem 0rem;
    color: black;
    background-color: rgb(17, 75, 137);
    color: white;
    width: 100%;
    text-align: center;
    font-weight: 450;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    overflow-x: hidden;
} 

.list{
    width: 100%;
    border: 1px solid rgb(69, 65, 65);
    padding: 0 1rem 0 1rem;
    margin-bottom: 2rem;
    overflow-x: scroll;
}

.loader{
    display: flex;
    justify-content: center;
    align-items: center;
}

.ld{
    /* font-size: 2rem; */
}